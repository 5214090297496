<template>
  <v-list-item
    :value="establishmentUser"
  >
    <template v-slot:default="{ active }">
      <v-list-item-action>
        <v-checkbox
          :value="active"
          color="primary"
        />
      </v-list-item-action>

      <v-list-item-content>
        <v-list-item-title>{{ establishment.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="active">
          Role : {{ establishmentUser.role }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="active">
        <EstablishmentUserRoleMenu
          :value="establishmentUser.role"
          @input="setEstablishmentUserRole"
        />
      </v-list-item-action>
    </template>
  </v-list-item>
</template>

<script>
import EstablishmentUserRoleMenu from '@/pages/App/Users/__EstablishmentUserRoleMenu__'

export default {
  name: 'EstablishmentUserListItem',
  components: { EstablishmentUserRoleMenu },
  props: {
    establishment: { type: Object, required: true },
    companyId: { type: String, required: true },
    userId: { type: String, required: true }
  },
  data () {
    return {
      establishmentUser: {
        establishmentId: this.establishment.id,
        role: 'GUEST',
        userId: this.userId,
        companyId: this.companyId
      }
    }
  },
  methods: {
    setEstablishmentUserRole (role) {
      this.establishmentUser.role = role
    }
  }
}
</script>
