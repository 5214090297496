<template>
  <div>
    <CrudCardTable
      :items="companiesUsers"
      :headers="headers"
      title="Company Members"
      button-text="Add"
      @create="dialog=true"
    >
      <template #item.user="{ item }">
        <router-link :to="{ name: 'App-Users-id', params: { id: item.id } }">
          {{ item.user.firstName }} {{ item.user.lastName }}
        </router-link>
      </template>
    </CrudCardTable>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      max-width="600px"
    >
      <InviteUserForm @success="dialog=false" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CrudCardTable from '@/components/CrudCardTable'
import InviteUserForm from '@/pages/App/Users/__InviteUserCard__'
import { CompanyUser } from '@/store/models'

export default {
  name: 'CompanyUsersList',
  components: { InviteUserForm, CrudCardTable },
  data () {
    return {
      headers: [
        { text: 'User', value: 'user' }
      ],
      dialog: false
    }
  },
  computed: {
    ...mapGetters('app', ['activeCompany']),
    companiesUsers () {
      return CompanyUser.query().with('user').where('companyId', this.activeCompany.id).get()
    }
  }
}
</script>
