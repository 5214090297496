var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"find-user"}},[_c('v-card',[_c('v-card-text',{attrs:{"align":"center"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-email-outline ")]),_c('div',{staticClass:"ma-2 text-h6"},[_vm._v(" Invite a user to join "+_vm._s(_vm.activeCompany.name)+" ")])],1),_c('v-card-actions',[_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"outlined":"","dense":"","chips":"","hide-no-data":"","hide-details":"","label":"Search user by email","item-text":"email","return-object":"","append-icon":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"blue-grey","small":""}},'v-chip',attr,false),on),[_c('span',{domProps:{"textContent":_vm._s(((item.firstName) + " " + (item.lastName)))}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attr = ref.attr;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":_vm.existingUserIds.includes(item.id)}},'v-list-item',attr,false),on),[_c('v-list-item-avatar',{staticClass:"text-h5 font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.firstName.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(((item.firstName) + " " + (item.lastName)))}}),(_vm.existingUserIds.includes(item.id))?_c('v-list-item-subtitle',[_vm._v(" Already a member ")]):_vm._e()],1)],1)]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('v-btn',{attrs:{"color":"success","disabled":!_vm.model},on:{"click":function($event){_vm.step='grant-access'}}},[_vm._v(" Invite ")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"grant-access"}},[(_vm.model)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Invite "+_vm._s(_vm.model.firstName)+" "+_vm._s(_vm.model.lastName)+" to join "+_vm._s(_vm.activeCompany.name)+" ")]),_c('v-divider'),_c('v-card-subtitle',[_vm._v("Add this user to some establishment with the appropriate role.")]),_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Establishments -- (Optional) ")]),_c('v-list',{attrs:{"outlined":"","dense":""}},[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.establishments),function(establishment,index){return [_c('EstablishmentUserListItem',{key:establishment.id,attrs:{"establishment":establishment,"company-id":_vm.activeCompany.id,"user-id":_vm.model.id}}),(index < _vm.establishments.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.inviteUser}},[_vm._v(" Confirm ")]),_c('v-spacer')],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }