<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-cog-outline</v-icon>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item-group
        v-model="selected"
        @change="$emit('input', selected)"
      >
        <v-list-item
          v-for="role in roles"
          :key="role"
          :value="role"
          dense
        >
          <v-list-item-title>
            {{ role }}
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'EstablishmentUserRoleMenu',
  props: {
    value: {
      type: String,
      default: 'GUEST'
    }
  },
  data () {
    return {
      selected: this.value,
      roles: ['OWNER', 'MANAGER', 'BAR_STAFF', 'WAITER', 'KITCHEN_STAFF', 'DOOR_STAFF', 'GUEST']
    }
  }
}
</script>
