<template>
  <v-stepper v-model="step">
    <v-stepper-items>
      <v-stepper-content step="find-user">
        <v-card>
          <v-card-text align="center">
            <v-icon
              large
            >
              mdi-email-outline
            </v-icon>
            <div class="ma-2 text-h6">
              Invite a user to join {{ activeCompany.name }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-autocomplete
              v-model="model"
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              outlined
              dense
              chips
              hide-no-data
              hide-details
              label="Search user by email"
              item-text="email"
              return-object
              append-icon=""
            >
              <template v-slot:selection="{ attr, on, item }">
                <v-chip
                  v-bind="attr"
                  color="blue-grey"
                  class="white--text"
                  small
                  v-on="on"
                >
                  <span v-text="`${item.firstName} ${item.lastName}`" />
                </v-chip>
              </template>
              <template v-slot:item="{ item , on, attr}">
                <v-list-item
                  v-bind="attr"
                  :disabled="existingUserIds.includes(item.id)"
                  v-on="on"
                >
                  <v-list-item-avatar
                    color="indigo"
                    class="text-h5 font-weight-light white--text"
                  >
                    {{ item.firstName.charAt(0) }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="`${item.firstName} ${item.lastName}`" />
                    <v-list-item-subtitle
                      v-if="existingUserIds.includes(item.id)"
                    >
                      Already a member
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <v-btn
              color="success"
              :disabled="!model"
              @click="step='grant-access'"
            >
              Invite
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
      <v-stepper-content step="grant-access">
        <v-card v-if="model">
          <v-card-title class="text-h5">
            Invite {{ model.firstName }} {{ model.lastName }} to join {{ activeCompany.name }}
          </v-card-title>
          <v-divider />
          <v-card-subtitle>Add this user to some establishment with the appropriate role.</v-card-subtitle>
          <v-card-title class="text-h6">
            Establishments -- (Optional)
          </v-card-title>
          <v-list
            outlined
            dense
          >
            <v-list-item-group
              v-model="selected"
              multiple
            >
              <template
                v-for="(establishment, index) in establishments"
              >
                <EstablishmentUserListItem
                  :key="establishment.id"
                  :establishment="establishment"
                  :company-id="activeCompany.id"
                  :user-id="model.id"
                />
                <v-divider
                  v-if="index < establishments.length - 1"
                  :key="index"
                />
              </template>
            </v-list-item-group>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              @click="inviteUser"
            >
              Confirm
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { mapGetters } from 'vuex'
import rulesMixin from '@/mixins/rulesMixin'
import EstablishmentUserListItem from '@/pages/App/Users/__EstablishmentUserListItem__'
import { actionService } from '@/services'
import { CompanyUser, Establishment, EstablishmentUser } from '@/store/models'

export default {
  name: 'InviteUserCard',
  components: { EstablishmentUserListItem },
  mixins: [rulesMixin],
  data () {
    return {
      items: [],
      search: null,
      isLoading: false,
      model: null,
      step: 'find-user',
      selected: []
    }
  },
  computed: {
    ...mapGetters('app', ['activeCompany']),
    existingUserIds () {
      return CompanyUser.all().map(companyUser => companyUser.userId)
    },
    establishments () {
      return Establishment.query().where('companyId', this.activeCompany.id).all()
    }
  },
  watch: {
    search: function (val) {
      if (this.rules.email(val) === true) {
        this.searchUser()
      }
    }
  },
  created () {
    Establishment.api().get()
  },
  methods: {
    async searchUser () {
      this.isLoading = true
      const { data } = await actionService.searchUser({ email: this.search })
      this.items = data.map(x => {
        x.email = this.search
        return x
      })
      this.isLoading = false
    },
    async inviteUser () {
      const { response } = await CompanyUser.api().post(null, { userId: this.model.id, companyId: this.activeCompany.id })
      this.selected.map(establishmentUser => {
        establishmentUser.companyUserId = response.data.id
        EstablishmentUser.api().post(null, establishmentUser)
      })
      this.$emit('success')
    }
  }

}
</script>
